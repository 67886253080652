<template>
  <div class="dashboard">
    <!-- Dashboard Sidebar Dialog List Start -->
    <div class="sidebar j-sidebar active">
      <div class="sidebar__inner">
        <div class="sidebar__header">
          <div class="dashboard__status_wrap">
            <input
              type="text"
              class="form-control"
              placeholder="Search merchant..."
              v-model="searchDialogField"
            />
          </div>
        </div>
        <div class="sidebar__content">
          <div class="m-auto" v-if="$store.getters.getIsLoading['dialogList']">
            <font-awesome icon="spinner" size="6x" spin />
          </div>
          <ul class="sidebar__dilog_list j-sidebar__dilog_list p-0" v-else>
            <li
              @click="openChat(dialog)"
              class="dialog__item j-dialog__item"
              :class="{
                selected: currentDialog && currentDialog._id === dialog._id,
              }"
              v-for="(dialog, i) in filteredDialogList"
              :key="i"
            >
              <a class="dialog__item_link">
                <span class="dialog__avatar">
                  <i class="fas fa-user-circle" />
                </span>
                <span class="dialog__info">
                  <span class="dialog__name">{{ dialog.name }}</span>
                  <span class="dialog__last_message j-dialog__last_message">
                    <span v-if="/attachment/i.test(dialog.last_message)">
                      <img src="@/assets/img/image-preview.png" width="15" />
                      Image
                    </span>
                    <span v-else-if="/WHIZPVL#/.test(dialog.last_message)">
                      {{ `Product: ${dialog.last_message.split("#")[3]}` }}
                    </span>
                    <span v-else-if="/WHIZPVLO#/.test(dialog.last_message)">
                      {{ `Order: ${dialog.last_message.split("#")[1]}` }}
                    </span>
                    <span v-else>
                      {{ dialog.last_message }}
                    </span>
                  </span>
                </span>
                <span class="dialog_additional_info">
                  <span
                    class="
                      dialog__last_message_date
                      j-dialog__last_message_date
                    "
                  >
                    {{ formatTimestamp(dialog.last_message_date_sent) }}
                  </span>
                  <span
                    v-if="dialog.unread_messages_count > 0"
                    class="dialog_unread_counter j-dialog_unread_counter"
                  >
                    {{ dialog.unread_messages_count }}
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Dashboard Sidebar Dialog List End -->
    <!-- Dashboard Content Message List Start -->
    <div class="content j-content">
      <div class="content__title j-content__title">
        <template v-if="Object.keys(currentDialog).length === 0">
          Welcome to Whizliz Chat
        </template>
        <template v-else>
          <font-awesome
            icon="arrow-left"
            color="black"
            class="open_sidebar"
            @click="toggleChat"
          />
          <span class="dialog__avatar">
            <i class="fas fa-user-circle" />
          </span>
          <span class="dialog__info">
            <span class="dialog__name">{{ currentDialog.name }}</span>
            <!-- TODO Online/Offline Status -->
            <!-- <span class="dialog__online_status">Online</span> -->
          </span>
        </template>
      </div>
      <div class="content__inner j-content__inner">
        <div
          class="welcome__message"
          v-if="Object.keys(currentDialog).length === 0"
        >
          <p>Please select user to start chatting.</p>
        </div>
        <div
          class="welcome__message"
          v-else-if="$store.getters.getIsLoading['messageList']"
        >
          <font-awesome icon="spinner" size="6x" spin />
        </div>
        <template v-else>
          <!-- TODO load more message  -->
          <!-- <div class="messages j-messages" @scroll="loadMoreMessages($event)"> -->
          <div class="messages j-messages">
            <template v-for="(data, date) of messageList">
              <div class="message__wrap sticky-top" :key="date">
                <div class="message__date_ids">
                  {{ formatDate(date) }}
                </div>
              </div>
              <div
                class="message__wrap"
                v-for="message in messageList[date]"
                :key="message._id"
              >
                <div class="message__content">
                  <div
                    class="bubble-chat"
                    :class="{
                      self: message.sender_id === merchantData.quickblox_id,
                    }"
                  >
                    <div
                      class="message__sender_name"
                      v-if="
                        currentDialog.type == 2 &&
                        message.sender_id != merchantData.quickblox_id &&
                        message.full_name
                      "
                    >
                      {{ message.full_name }}
                    </div>
                    <span
                      v-if="
                        message.message && message.message.startsWith('WHIZPVL')
                      "
                      class="message__item_link"
                    >
                      <span class="dialog__avatar">
                        <img
                          :src="message.message.split('#')[4]"
                          class="h-100 bg-white"
                        />
                      </span>
                      <span class="dialog__info">
                        <!-- Order Status / Product Name -->
                        <span
                          class="private__link_name"
                          :class="
                            message.message.includes('HSO')
                              ? 'private__link_order_status'
                              : ''
                          "
                        >
                          {{ message.message.split("#")[3] }}
                        </span>
                        <!-- Order Number -->
                        <span
                          class="private__link_name"
                          v-if="message.message.includes('HSO')"
                        >
                          {{ message.message.split("#")[1] }}
                        </span>
                        <!-- Order / Product Price Info -->
                        <span class="private__link_price">
                          {{ message.message.split("#")[2] }}
                        </span>
                        <a
                          class="private__link_detail cursor-pointer"
                          @click="viewPreviewLink(message.message)"
                        >
                          View Detail
                        </a>
                      </span>
                    </span>
                    <div
                      v-else-if="
                        message.attachments && message.attachments.length > 0
                      "
                    >
                      <img
                        class="message_attachment cursor-pointer"
                        :src="attachment.src"
                        v-for="(attachment, i) in message.attachments"
                        :key="i"
                        @load="loadImagesEvent"
                        @click="selectedImageSrc = attachment.src"
                        v-b-modal.modal-image
                      />
                    </div>
                    <span class="message__text" v-else>
                      {{ message.message }}
                    </span>

                    <span class="message__timestamp">
                      {{ formatTimestamp(message.date_sent) }}
                      <img
                        v-if="message.sender_id === merchantData.quickblox_id"
                        :src="
                          message.status == 'read'
                            ? require('@/assets/img/icon_read.png')
                            : require('@/assets/img/icon_unread.png')
                        "
                        height="12"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="message__template">
            <div v-for="(msgTemplate, i) in messageTemplates" :key="i" @click="useMessageTemplate(msgTemplate)">{{ msgTemplate }}</div>
          </div>
          <form @submit.prevent="onSubmit">
            <div class="message__field_form">
              <label for="image-upload" class="cursor-pointer pr-2 m-auto">
                <img src="@/assets/img/icon_attach.png" width="25" />
              </label>
              <input
                id="image-upload"
                type="file"
                class="d-none"
                @input="uploadFile"
                accept=".jpg, .jpeg, .png"
              />
              <textarea
                class="message__field form-control"
                placeholder="Type a message"
                place
                rows="1"
                data-min-rows="1"
                v-model="messageField"
                @keydown="onKeydown"
                @input="autoExpandRows"
              />
            </div>
          </form>
        </template>
      </div>
    </div>
    <!-- Dashboard Content Message List End -->
    <ModalOrderDetail :orderId="selectedOrderId" />
    <ModalImage :src="selectedImageSrc" />
  </div>
</template>
<script>
import ModalOrderDetail from "@/components/orderList/ModalOrderDetail";
const ModalImage = () => import('@/components/shared/ModalImage'); 

import { qbHelper, toaster } from "@/_helpers";
import moment from "moment";

export default {
  name: "Livechat",
  components: {
    ModalOrderDetail,
    ModalImage,
  },
  data() {
    return {
      searchDialogField: "",
      messageField: "",
      selectedOrderId: "", // Preview link for detail order

      messageTemplates: [
        "Halo, barang ini ready. Silakan diorder",
        "Bisa dikirim hari ini",
        "Terima kasih!"
      ],

      selectedImageSrc: "",
    };
  },
  computed: {
    merchantData() {
      return this.$store.getters.getMerchantData;
    },
    dialogList() {
      return this.$store.getters.getDialogList;
    },
    filteredDialogList() {
      return Object.values(this.dialogList).filter((dialog) => {
        return dialog.name
          .toLowerCase()
          .includes(this.searchDialogField.toLowerCase());
      });
    },
    currentDialog() {
      return this.$store.getters.getCurrentDialog;
    },
    messageList() {
      return this.$store.getters.getMessageList;
    },
  },
  mounted() {
    // Scroll to bottom if message list container loaded
    const container = document.querySelector(".j-messages");
    if (container) qbHelper.scrollTo(container, "bottom");
  },
  methods: {
    viewPreviewLink(message) {
      // If preview link is detail product
      if (/WHIZPVL#/.test(message)) {
        this.$router.push(`/detail-product/${message.split("#")[1]}`);
      }
      // If preview link is detail order
      else if (/WHIZPVLO#/.test(message)) {
        this.selectedOrderId = message.split("#")[1];
        this.$bvModal.show("modal-order-detail");
        // this.$root.$emit("bv::modal::show", "modal-order-detail");
      }
    },
    openChat(dialog) {
      this.toggleChat();
      // If user selecting other different dialog
      if (dialog._id != this.currentDialog._id)
        this.$store.dispatch("setCurrentDialog", dialog);
    },

    // For mobile responsivity
    toggleChat() {
      const el = document.querySelector(".j-sidebar");
      if (!el.classList.contains("active")) el.classList.add("active");
      else el.classList.remove("active");
    },

    onSubmit() {
      if (this.messageField) {
        qbHelper.sendMessage(this.messageField, null);
        this.$api.firebase.sendFCM(this.messageField);
        setTimeout(() => {
          qbHelper.scrollTo(document.querySelector(".j-messages"), "bottom");
        }, 200);
        this.messageField = ""; // Clear chat after send message
      }
    },

    // Upload File Handler Start
    async uploadFile(e) {
      const file = e.target.files[0];
      if (!file) return
      if (!/image\/jpg|png|jpeg/.test(file.type)) return toaster.make("Please select an image file (jpg, jpeg, png)", "danger", null, 2000);
      this.compressImage(file);
    },

    compressImage(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.filename = file.name

      reader.onload = (event) => {
        const imgElement = document.createElement("img");
        imgElement.src = event.target.result;
        
        imgElement.onload = (event) => {
          const canvas = document.createElement("canvas");
          const maxDimension = 600;

          const imgWidth = event.target.width;
          const imgHeight = event.target.height;
          

          if (imgWidth > maxDimension || imgHeight > maxDimension) {
            // Condition of which dimension is larger to scale the correct image size
            const largerHeight = imgHeight > imgWidth;
            const scaleSize =  maxDimension / (largerHeight ? imgWidth : imgHeight);
            canvas.width = largerHeight ? maxDimension : imgWidth * scaleSize;
            canvas.height = largerHeight ? imgHeight * scaleSize : maxDimension;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(event.target, 0, 0, canvas.width, canvas.height);
  
            const srcEncoded = canvas.toDataURL("image/jpeg");
            let compressedFile = this.dataURItoBlob(srcEncoded);
            compressedFile.filename = reader.filename;
            qbHelper.createAndUpload(compressedFile);
          } else {
            qbHelper.createAndUpload(file);
          }
        }
      }
    },

    // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    // Upload File Handler End

    // Auto expand rows Start
    // Auto expand message field row(s) when typing
    autoExpandRows() {
      const elm = document.querySelector(".message__field");
      let minRows = parseInt(elm.getAttribute("data-min-rows"));
      !elm._baseScrollHeight && this.getScrollHeight(elm);

      elm.rows = minRows;
      let rows = Math.ceil((elm.scrollHeight - elm._baseScrollHeight) / 24);
      elm.rows = minRows + rows;
    },

    getScrollHeight(elm) {
      var savedValue = elm.value;
      elm.value = "";
      elm._baseScrollHeight = elm.scrollHeight;
      elm.value = savedValue;
    },
    // Auto expand rows End

    // TODO fix scroll after image loaded (the code still not optimal)
    // Show image attachment in message after image loaded
    loadImagesEvent(e) {
      let img = e.target,
        container = document.querySelector(".j-messages"),
        scrollHeight = container.scrollTop + img.offsetHeight;

      img.classList.add("loaded");

      if (container) {
        container.scrollTop = scrollHeight + 65;
      }
    },

    // For handle typing in messageField (Shift+Enter > new line | Enter > onSubmit)
    onKeydown(e) {
      if (e.keyCode == 13 && !e.shiftKey) {
        e.preventDefault();
        this.onSubmit();
      }
    },

    // Formatting Start
    formatDate(value) {
      if (value) {
        // Get date now in ms timestamp
        const dateNow = moment.now();

        // Get value in ms timestamp
        const dateValue = parseInt(moment(value).format("x"));

        // Do the math
        const msPerDay = 1000 * 60 * 60 * 24;
        const msBetween = dateNow - dateValue;
        const days = Math.floor(msBetween / msPerDay);

        if (days === 0) {
          return "Today";
        } else if (days === 1) {
          return "Yesterday";
        } else {
          return moment(value).format("DD/MM/YYYY").toString();
        }
      }
    },
    formatTimestamp(value) {
      return value ? moment.unix(value).format("HH:mm").toString() : value;
    },
    // Formatting End

    useMessageTemplate(val) {
      this.messageField = this.messageField + (this.messageField && this.messageField.slice(-1) != " " ? " " : "") + val
    }, 
  },
};
</script>

<style scoped>
.dashboard {
  max-width: 970px;
  border-top: none;
  border: 1px solid #dadfe1;
  margin: 0 auto;
  background-color: white;
  box-sizing: border-box;
  height: 520px;
  display: flex;
  flex-wrap: nowrap;
}

.sidebar {
  position: fixed;
  left: -100%;
  background: #fff;
  width: 100%;
  height: 100%;
  border-right: 1px solid #dadfe1;
  overflow: hidden;
  z-index: 1021;
}

.sidebar.active {
  left: 0;
}

.sidebar__inner {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100%;
}

.sidebar__header {
  display: flex;
  flex-wrap: nowrap;
  flex: 0 0 80px;
  box-sizing: border-box;
  padding: 21px 19px 18px;
  border-bottom: 1px solid #dadfe1;
}

.dashboard__logo img {
  width: 41px;
  height: 41px;
}

.dashboard__status_wrap {
  flex: 1 1 100%;
}

.dashboard__title {
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  color: #4a4a4a;
  margin: 0 0 6px 0;
}

.sidebar__content {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow: hidden;
}

.dialog__avatar,
.message__avatar,
.user__avatar {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0 16px 0 0;
  position: relative;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.dialog__avatar i,
.message__avatar i,
.user__avatar i {
  font-size: 57px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #d89700;
}

.dialog__avatar.m-type_group i {
  font-size: 35px;
}

.message__avatar {
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
}

.message__avatar i {
  font-size: 46px;
}

.user__avatar {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
}

.user__avatar i {
  font-size: 50px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 660px;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.content__title {
  display: flex;
  align-items: center;
  padding: 15px 16px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #4a4a4a;
  border-bottom: 1px solid #dadfe1;
  flex: 0 0 80px;
  box-sizing: border-box;
}

.content__inner {
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}

/*
    WELCOME MESSAGE
*/

.welcome__message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  text-align: center;
  height: 100%;
  color: #778594;
  user-select: none;
}

/*
    END WELCOME MESSAGE
*/

/* .load_more__btn {
  display: block;
  color: white;
  background-color: #4054b2;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  width: 130px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 auto 20px auto;
} */

.messages {
  flex: 1 1 100%;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 16px;
  width: 100%;
}

.messages.loading:before {
  content: "";
  display: block;
  /* background: url(../../assets/img/loader2.gif); */
  width: 30px;
  height: 30px;
  background-size: contain;
  margin: 15px auto;
}

.message__wrap {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 0 16px 0;
}

.message__wrap .m-notification_message {
  width: 100%;
  color: #778594;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
}

.message__wrap.m-typing {
  align-items: center;
}

.message__content {
  flex: 1 0;
  flex-wrap: nowrap;
  /* overflow: hidden; */
}

.message__sender_and_status {
  display: flex;
  margin: 0 0 4px 0;
}

.message__text_and_date {
  display: flex;
}

.message__text_wrap {
  flex: 1 0;
}

.message__text {
  margin: 0 0 10px 0;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  overflow: hidden;
  white-space: pre-line;
  word-break: break-all;
}

.message__text a {
  color: #366ba9;
}

.message__timestamp {
  opacity: 0.6;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #4a4a4a;
  float: right;
  margin-left: 10px;
  margin-top: 7px;
}

.message_attachment {
  width: 100%;
  max-width: 328px;
  position: absolute;
  visibility: hidden;
  display: block;
  margin: 0 0 5px 0;
}

.message_attachment.loaded {
  position: static;
  visibility: visible;
}

.attachment__error {
  font-size: 14px;
  color: red;
}

.send_message {
  border-top: 1px solid #dadfe1;
}

.message__actions {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 15px 25px;
}

.attachments_preview {
  flex: 1 1 100%;
}

.attach_btn {
  flex: 0 0 20px;
  overflow: hidden;
  color: #778594;
  cursor: pointer;
}

.attach_btn i {
  font-size: 22px;
  display: inline-block;
  vertical-align: middle;
}

.attach_input {
  display: none;
}

.attachment_preview__wrap {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  position: relative;
  margin: 0 5px 0 0;
}

.attachment_preview__wrap.m-loading:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background: url(../../assets/img/loader2.gif) no-repeat 50% 50%; */
  background-size: 20px;
}

.attachment_preview__wrap.m-loading .attachment_preview__item {
  animation: blink 1s infinite alternate;
}

.attachment_preview__item {
  max-width: 25px;
  max-height: 25px;
}

.send_btn {
  flex: 0 0 38px;
  cursor: pointer;
  color: #17d04b;
  background: transparent;
  border: none;
  cursor: pointer;
  font: 500 15px/18px "Roboto", sans-serif;
  text-transform: uppercase;
}

.back_to_dialog {
  border: none;
  width: 24px;
  height: 24px;
  color: #778594;
  background: transparent;
  cursor: pointer;
  flex: 0 0 24px;
  padding: 0;
  margin: 0 16px 0 0;
}

.back_to_dialog:hover {
  color: #18c942;
}

.group_chat__title,
.update_chat__title_input {
  font-size: 22px;
  line-height: 25px;
  color: #4a4a4a;
  flex: 1 1 100%;
}

.update_chat_name_form {
  display: flex;
  flex: 1 1 100%;
}

.update_chat__title_input {
  border: none;
  background: #fff;
  margin: 0 20px 0 0;
  padding: 10px 15px 10px 0;
  box-shadow: inset 0 -2px 0 0 #18c942;
  transition: 0.3s;
}

.update_dialog__header {
  padding: 6px 16px;
  align-items: center;
}

.content__title .action_links {
  display: flex;
  flex: 1 1;
}

.content__title .action_links a {
  color: #778594;
}

.content__title .action_links a:hover {
  color: #18c942;
}

.content__title .action_links .hidden {
  display: none;
}

.content__title .action_links .add_to_dialog {
  margin: 0 17px 0 0;
}

.update_chat__title_input:disabled {
  box-shadow: none;
}

.update_chat__title_button i {
  color: #6c7a89;
  cursor: pointer;
}

.update_chat__title_button:hover i,
.update_update_chat_name_form.active i {
  color: #18c942;
}

.update_chat__title_button {
  border: none;
  background: #fff;
}

.group_chat__user_list,
.update_chat__user_list {
  flex: 1 1 100%;
  overflow: hidden;
  overflow-y: auto;
}

.group_chat__user_list.loading:after {
  content: "";
  display: block;
  /* background: url(../../assets/img/loader2.gif); */
  width: 30px;
  height: 30px;
  background-size: contain;
  margin: 15px auto;
}

.group__chat__description,
.update__chat__description {
  font-size: 15px;
  line-height: 18px;
  color: #778594;
  padding: 7px 24px;
}

.user__item {
  padding: 8px 24px;
  display: flex;
  cursor: pointer;
}

.user__item:hover,
.user__item.selected {
  background-color: #f5f4f2;
}

.user__item.selected .user__avatar,
.user__item.disabled .user__avatar {
  background-color: #28c849;
  /* background-image: url("../../assets/img/ic_done.svg"); */
}

.user__item.disabled {
  background-color: #ffffff;
}

.user__item.disabled .user__avatar {
  background-color: #a6a6a6;
}

.user__item.selected i {
  display: none;
}

.user__name {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #4a4a4a;
  margin: 0 0 3px 0;
}

.user__last_seen {
  font-size: 13px;
  line-height: 15px;
  color: #778594;
}

.dialog_form {
  border-top: 1px solid #dadfe1;
  display: flex;
  flex: 0 0 66px;
  padding: 13px 18px 13px 8px;
}

.dialog_form.m_dialog_form_update {
  justify-content: flex-end;
}

.dialog_name {
  flex: 1 1 100%;
  font-size: 16px;
  line-height: 19px;
  border: none;
  margin: 0 10px 0 0;
  padding: 0 10px;
}

.dialog_name:disabled {
  cursor: no-drop;
}

.m-update_dialog_btn_cancel {
  margin: 0 16px 0 0;
  background: #fff;
  color: #778594;
}

@keyframes blink {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.5;
  }
}

@media screen and (min-width: 880px) {
  .sidebar {
    flex: 1 0 320px;
    position: static;
    left: 100%;
  }

  .open_sidebar {
    display: none;
  }
}

/* Dialog Styles */

.sidebar__dilog_list {
  flex: 1 1 100%;
  overflow-y: auto;
  text-align: left;
}

.sidebar__dilog_list.loading:after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-size: contain;
  margin: 15px auto;
}

.sidebar__dilog_list .dialog__item {
  display: block;
  width: 100%;
}

.sidebar__dilog_list .dialog__item.selected,
.sidebar__dilog_list .dialog__item:hover {
  background: rgba(42, 100, 150, 0.1);
}

.dialog__item_link {
  padding: 16px;
  display: flex;
  flex-wrap: nowrap;
  text-decoration: none;
}

.open_sidebar {
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  flex: 0 0 24px;
  padding: 0;
  margin: 0 16px 0 0;
}

.dialog__title {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #4a4a4a;
  flex: 1 1 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog__item {
  cursor: pointer;
}

.dialog__info {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.dialog__name {
  display: block;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #4a4a4a;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 10px 6px 0;
}

.dialog__last_message,
.dialog__online_status {
  display: block;
  width: 100%;
  font-size: 13px;
  line-height: 15px;
  color: #778594;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dialog__last_message.attachment {
  color: #007aff;
}

.dialog_additional_info {
  flex: 0 0 35px;
  text-align: right;
}

.dialog__last_message_date {
  font-size: 12px;
  line-height: 14px;
  color: #4a4a4a;
  margin: 0 0 2px 0;
  display: inline-block;
}

.dialog_unread_counter {
  min-width: 23px;
  background-color: #17d04b;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  color: #ffffff;
  text-align: center;
  display: inline-block;
}

.dialog_unread_counter.hidden {
  display: none;
}

.center {
  width: 100%;
  text-align: center;
}

/* Custom CSS for Chat Start */
/* Bubble Chat Start */
.bubble-chat {
  float: left;
  position: relative;
  max-width: 325px;
  padding: 0.5rem;
  text-align: left;
  border-radius: 0.25rem;
  background-color: #f5f5f5;
}
.bubble-chat:before {
  content: "";
  position: absolute;
  border-left: 19px solid #f5f5f5;
  border-top: 4px solid transparent;
  border-bottom: 12px solid transparent;
  bottom: -8px;
  left: 8px;
}
.bubble-chat.self:before {
  border-left: unset;
  border-right: 19px solid #f8e1b0;
  right: 8px;
  left: unset;
}
.bubble-chat.self {
  float: right;
  background-color: #f8e1b0;
}
/* Bubble Chat End */

/* Message Preview Link Start */
.message__item_link {
  padding: 12px;
  display: flex;
  flex-wrap: nowrap;
  text-decoration: none;
}
.message__date_ids {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #cfcfcf;
  margin: auto;
  width: 100px;
  text-align: center;
}
.private__link_name {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 10px 6px 0;
}
.private__link_price {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 10px 6px 0;
  color: red;
}
.private__link_detail {
  font-size: 14px;
  color: #366ba9;
}
.private__link_order_status {
  font-size: 12px !important;
  color: #4caf50;
}
/* Message Preview Link End */

/* Send Message Form Start */
.message__field_form {
  border-top: 1px solid #dadfe1;
  display: flex;
  padding: 1rem;
}
.message__field_form::-webkit-input-placeholder {
  color: #c5cace;
}
.message__field_form::-moz-placeholder {
  color: #c5cace;
}
.message__field_form:-moz-placeholder {
  color: #c5cace;
}
.message__field_form:-ms-input-placeholder {
  color: #c5cace;
}
.message__field {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  resize: none;
}
.message__sender_name {
  color: #4caf50;
  font-size: 12px;
  font-weight: 700;
  line-height: 25px;
  flex: 1 0;
}
/* Send Message Form End */
/* Custom CSS for Chat End */

/* Messages template START */
.message__template {
  position: relative;
  display: flex;
  background: transparent;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  padding: 10px 16px;
  cursor: pointer;
}

.message__template div {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  font-size: 12px;
  padding: 10px 6px;
  border: 1px solid black;
}

.message__template div:not(:last-child) {
  margin-right: 4px;
}

.message__template div:hover {
  background: #e2e2e2;
}
/* Messages template END */
</style>
